import { type Place } from '@/features/place/types/place-types';
import convertCoordinatesToBbox from '@/features/place/utils/convert-coordinates-to-bbox';
import { type Feature } from '@/libs/geocode-earth/geocode-earth-types';

export default function placeAdapter(feature: Feature): Place {
  const bbox: Place['bbox'] = feature.bbox
    ? {
      latitudeNorthEast: feature.bbox[3],
      latitudeSouthWest: feature.bbox[1],
      longitudeNorthEast: feature.bbox[2],
      longitudeSouthWest: feature.bbox[0],
    }
    : convertCoordinatesToBbox({
      latitude: feature.geometry.coordinates[1],
      longitude: feature.geometry.coordinates[0],
    });

  return {
    bbox,
    fullName: feature.properties.label,
    geometry: {
      coordinates: {
        latitude: feature.geometry.coordinates[1],
        longitude: feature.geometry.coordinates[0],
      },
    },
    id: feature.properties.id,
  };
}
