import { type CurrencySymbols } from '@/features/currency/types/currency-types';
import { type Language } from '@/features/language/types/language-types';
import { getUserBearerCookie } from '@/features/user/cookies/cookie-user-bearer';

export default async function updateLoggedUserLocalization(locale: Language['locale'], currency: CurrencySymbols['name']) {
  const bearerToken = getUserBearerCookie();

  const headers: HeadersInit = {};

  if (bearerToken) {
    headers.Authorization = `Bearer ${bearerToken}`;
    headers.Locale = locale;
    headers.Currency = currency;
  }

  const createListingBookmarksResponse = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/v1/localizationsettings`, {
    cache: 'no-store',
    headers,
    method: 'PUT',
  });

  if (!createListingBookmarksResponse.ok) {
    throw new Error('Failed to fetch data');
  }
}
