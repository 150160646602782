import {
  type Coordinates,
  type PlaceBoundingBox,
} from '@/features/place/types/place-types';

export default function convertCoordinatesToBbox(coords: Coordinates): PlaceBoundingBox {
  return {
    latitudeNorthEast: coords.latitude + 0.005,
    latitudeSouthWest: coords.latitude - 0.005,
    longitudeNorthEast: coords.longitude + 0.005,
    longitudeSouthWest: coords.longitude - 0.005,
  };
}
