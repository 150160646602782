'use client';

import './select.scss';

import { type Props } from 'react-select';
import ReactSelect from 'react-select';

export default function Select<T, F extends boolean>(props: Props<T, F>) {
  return (
    <div className="select-wrapper">
      <ReactSelect
        {...props}
        classNamePrefix="select"
      />
    </div>

  );
}
