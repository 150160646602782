import cx from 'classix';
import { type IconType } from 'react-icons';

import styles from './notifications-indicator.module.scss';

interface NotificationIndicatorProps {
  icon?: IconType;
  text: string | number;
  color?: 'green' | 'blue' | 'orange';
}

export default function NotificationsIndicator(props: NotificationIndicatorProps) {
  const {
    color = 'green',
    icon,
    text,
  } = props;

  return (
    <div
      className={cx(
        styles.notificationsIndicator,
        color === 'green' && styles.notificationsIndicator__isGreen,
        color === 'blue' && styles.notificationsIndicator__isBlue,
        color === 'orange' && styles.notificationsIndicator__isOrange,
      )}
    >
      {/* eslint-disable-next-line unicorn/no-keyword-prefix */}
      {icon?.({ className: styles.notificationsIndicator_icon })}
      {/* <FaBullhorn className={styles.headerLoginedLinks_messageIndicatorIcon} /> */}
      <div className={styles.notificationsIndicator_text}>
        {text}
      </div>
    </div>
  );
}
