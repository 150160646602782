import { getCookieUserCoordinates } from '@/features/place/cookies/cookie-user-coordinates';
import { type Coordinates } from '@/features/place/types/place-types';
import geocodeFilterSearchResults from '@/libs/geocode-earth/geocode-filter-search-results';

import {
  type Feature,
  type GeocodeEarthResponse,
} from './geocode-earth-types';

const config = {
  apiKey: process.env.NEXT_PUBLIC_GEO_SERVICE_API_KEY,
  autocompleteUrl: 'https://api.geocode.earth/v1/autocomplete',
  forwardUrl: 'https://api.geocode.earth/v1/search',
  reverseUrl: 'https://api.geocode.earth/v1/reverse',
  // scope: 'address,street,neighbourhood,borough,localadmin,locality,county,macrocounty,region,macroregion,country',
  // scopeFull: 'address,street,neighbourhood,borough,localadmin,locality,county,macrocounty,region,macroregion,country,postalcode',
  sources: 'openaddresses,openstreetmap,whosonfirst',
};

const geocodeEarthRequestParameters: Record<string, string> = {
  api_key: config.apiKey,
  lang: 'en',
  sources: config.sources,
};

export async function autocompleteByName(searchPlaceName: string) {
  const requestParameters: Record<string, string> = { ...geocodeEarthRequestParameters, text: searchPlaceName };
  const userCoordinates = getCookieUserCoordinates();

  if (userCoordinates) {
    requestParameters['focus.point.lat'] = String(userCoordinates.latitude);
    requestParameters['focus.point.lon'] = String(userCoordinates.longitude);
  }

  const urlParameters = new URLSearchParams(requestParameters).toString();
  const geocoderRequestUrl = `${config.autocompleteUrl}?${urlParameters}`;

  const result = await fetch(geocoderRequestUrl, { cache: 'no-store' });

  return result.json() as Promise<GeocodeEarthResponse>;
}

export async function searchPlaceByName(searchPlaceName: string): Promise<Feature> {
  const requestParameters: Record<string, string> = { ...geocodeEarthRequestParameters, text: searchPlaceName };
  const urlParameters = new URLSearchParams(requestParameters).toString();
  const geocoderRequestUrl = `${config.forwardUrl}?${urlParameters}`;
  const result = await fetch(geocoderRequestUrl, { cache: 'no-store' });
  const searchData = await result.json() as GeocodeEarthResponse;

  return geocodeFilterSearchResults(searchData.features, searchData.geocoding);
}

export async function searchByCoordinates(coords: Coordinates) {
  const requestParameters: Record<string, string> = {
    ...geocodeEarthRequestParameters,
    'point.lat': String(coords.latitude),
    'point.lon': String(coords.longitude),
    size: '1',
  };

  const urlParameters = new URLSearchParams(requestParameters).toString();
  const geocoderRequestUrl = `${config.reverseUrl}?${urlParameters}`;

  const result = await fetch(geocoderRequestUrl, { cache: 'no-store' });

  return result.json() as Promise<GeocodeEarthResponse>;
}
