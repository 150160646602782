import { type LocalStorage } from '@/shared/types/local-storage-types';

export function setTypedLocalStorageItem<T extends keyof LocalStorage>(key: T, value: LocalStorage[T]): void {
  window.localStorage.setItem(key, JSON.stringify(value));
}

export function getTypedLocalStorageItem<T extends keyof LocalStorage>(key: T): LocalStorage[T] | null {
  const value = window.localStorage.getItem(key);

  if (value) {
    return JSON.parse(value) as LocalStorage[T];
  }

  return null;
}

export function removeTypedLocalStorageItem<T extends keyof LocalStorage>(key: T): void {
  window.localStorage.removeItem(key);
}
