import {
  getCookie as getCookieClient,
  setCookie as setCookieClient,
} from 'cookies-next/client';
import {
  getCookie as getCookieServer,
  setCookie as setCookieServer,
} from 'cookies-next/server';

import { type ListingType } from '@/features/listing/types/listing-types';
import { DEFAULT_COOKIE_SETTING } from '@/shared/constants/cookie-constants';
import {
  type CookieGetData,
  type CookieReturnValue,
  type CookieSetData,
} from '@/shared/types/cookies-types';

const cookieName = 'service_type';

export function getCookieServiceType<IsServer extends boolean = false>(cookieOptions?: CookieGetData<IsServer>): CookieReturnValue<IsServer, ListingType> | null {
  if (cookieOptions?.isServer) {
    return (async () => {
      const cookie = await getCookieServer(cookieName, cookieOptions.options);

      if (cookie) {
        return cookie.toLowerCase() as ListingType;
      }

      return null;
    })() as CookieReturnValue<IsServer, ListingType>;
  }
  const cookie = getCookieClient(cookieName);

  if (cookie) {
    return cookie.toLowerCase() as CookieReturnValue<IsServer, ListingType>;
  }

  return null;
}

// eslint-disable-next-line consistent-return
export function setCookieServiceType<IsServer extends boolean = false>(cookieOptions: CookieSetData<IsServer>): CookieReturnValue<IsServer, void> | void {
  if (cookieOptions.isServer) {
    return (async () => {
      await setCookieServer(cookieName, cookieOptions.data, { ...DEFAULT_COOKIE_SETTING, ...cookieOptions.options });
    })() as CookieReturnValue<IsServer, void>;
  }

  setCookieClient(cookieName, cookieOptions.data);
}
