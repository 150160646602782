import { type SingleValueProps } from 'react-select';
import { components } from 'react-select';

import styles from '@/features/currency/components/currency-picker/currency-picker.module.scss';
import { type Currency } from '@/features/currency/types/currency-types';

export default function CurrencyValue(props: SingleValueProps<Currency>) {
  const {
    children,
    data,
  } = props;

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const currencySign = data.prefix || data.postfix;

  return (
    <components.SingleValue {...props}>
      <div className={styles.single_value}>
        {currencySign && <div className={styles.single_sign}>{currencySign}</div>}
        <span>{children}</span>
      </div>
    </components.SingleValue>
  );
}
