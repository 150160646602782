'use client';

import { useLocale } from 'next-intl';
import { useState } from 'react';
import { type SingleValue } from 'react-select';

import { getCookieCurrency } from '@/features/currency/cookies/cookie-currency';
import LanguageInput from '@/features/language/components/language-picker/language-input/language-input';
import {
  DEFAULT_LANGUAGE,
  SUPPORTED_LOCALES,
} from '@/features/language/language-constants';
import { type Language } from '@/features/language/types/language-types';
import { getUserBearerCookie } from '@/features/user/cookies/cookie-user-bearer';
import updateLoggedUserLocalization from '@/features/user/requests/update-logged-user-localization';
import {
  usePathname,
  useRouter,
} from '@/i18n/routing';
import Select from '@/ui/form-elements/select/select';

import LanguageOption from '../language-option/language-option';
import LanguageValue from '../language-value/language-value';

interface LanguageSelectProps {
  options: Language[]
}

export default function LanguageSelect(props: LanguageSelectProps) {
  const { options } = props;
  const router = useRouter();
  const pathname = usePathname();
  const locale = useLocale();

  const optionWithCurrentLocale = options.find((option) => option.locale === locale);
  const [selectedLanguage,
    setSelectedLanguage] = useState<SingleValue<Language>>(optionWithCurrentLocale ?? DEFAULT_LANGUAGE);

  const setLocalePath = (option: SingleValue<Language>) => {
    const currentCurrency = getCookieCurrency();
    const isUserLogged = getUserBearerCookie();

    const switchLanguage = () => {
      setSelectedLanguage(option);
      // @ts-expect-error - in onChange we check option on null
      router.push(pathname, { locale: option.locale });
    };

    if (isUserLogged) {
      // @ts-expect-error - in onChange we check option on null
      updateLoggedUserLocalization(option.locale, currentCurrency)
        .then(() => {
          switchLanguage();
        })
        .catch(() => null);
    } else {
      switchLanguage();
    }
  };

  const onChangeSelect = (option: SingleValue<Language>) => {
    if (option && SUPPORTED_LOCALES.includes(option.locale)) {
      setLocalePath(option);
    }
  };

  const getLanguageName = (language: Language) => language.name;

  return (
    <Select<Language, false>
      aria-label="language"
      placeholder="lang-select"
      value={selectedLanguage}
      instanceId="language-select"
      options={options}
      onChange={onChangeSelect}
      getOptionLabel={getLanguageName}
      getOptionValue={getLanguageName}
      components={{
        Input: LanguageInput,
        Option: LanguageOption,
        SingleValue: LanguageValue,
      }}
      isOptionDisabled={(option) => option.locale === selectedLanguage?.locale}
      menuPosition="fixed"
    />
  );
}
