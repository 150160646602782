'use client';

import { useAtom } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import { useSearchParams } from 'next/navigation';
import { useLocale } from 'next-intl';
import { useEffect } from 'react';
import { type SingleValue } from 'react-select';
import { useIsMounted } from 'usehooks-ts';

import {
  currenciesListAtom,
  currentCurrencyAtom,
} from '@/features/currency/atoms/currencies-atom';
import CurrencyInput from '@/features/currency/components/currency-picker/currency-input/currency-input';
import { setCookieCurrency } from '@/features/currency/cookies/cookie-currency';
// import getCurrenciesRates from '@/features/currency/requests/get-currencies-rates';
import {
  type CurrenciesList,
  type Currency,
  type CurrencySymbols,
} from '@/features/currency/types/currency-types';
import { LISTINGS_BUDGET_FILTER } from '@/features/listing/constants/listings-filters-constants';
import { getUserBearerCookie } from '@/features/user/cookies/cookie-user-bearer';
import updateLoggedUserLocalization from '@/features/user/requests/update-logged-user-localization';
import {
  usePathname,
  useRouter,
} from '@/i18n/routing';
import { setTypedLocalStorageItem } from '@/shared/utils/local-storage';
import Select from '@/ui/form-elements/select/select';

import CurrencyOption from '../currency-option/currency-option';
import CurrencyValue from '../currency-value/currency-value';

interface CurrencySelectProps {
  currencies: CurrenciesList,
  currentCurrency: Currency,
}

export default function CurrencySelect(props: CurrencySelectProps) {
  const {
    currencies,
    currentCurrency,
  } = props;

  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const locale = useLocale();
  const selectOptions = Object.values(currencies);
  const isMounted = useIsMounted();

  useHydrateAtoms([
    [currentCurrencyAtom, currentCurrency],
    [currenciesListAtom, currencies],
  ]);

  const [currency,
    setCurrency] = useAtom(currentCurrencyAtom);

  const calculateCurrency = (value: number, previousRate: number, selectedRate: number) => {
    if (previousRate !== selectedRate) {
      const inUsd = value / previousRate;
      const maxInCurrentCurrency = inUsd * selectedRate;

      return Math.ceil(maxInCurrentCurrency);
    }

    return value;
  };

  const onChangeSelect = (option: SingleValue<Currency>) => {
    if (option) {
      const previousCurrency = currency.name;
      const selectedRate = currencies[option.name].rate;
      const previousRate = currencies[previousCurrency].rate;
      const isUserLogged = getUserBearerCookie();

      if (isUserLogged) {
        updateLoggedUserLocalization(locale, option.name)
          .catch(() => null);
      }

      setCookieCurrency(option.name);
      setCurrency(option);

      const params = new URLSearchParams(searchParams.toString());
      const budgetMaxParameter = Number(params.get(LISTINGS_BUDGET_FILTER.options.max.name));
      const budgetMinParameter = Number(params.get(LISTINGS_BUDGET_FILTER.options.min.name));

      if (budgetMaxParameter && previousRate) {
        const nMax = String(calculateCurrency(budgetMaxParameter, previousRate, selectedRate));
        const nMin = String(calculateCurrency(budgetMinParameter, previousRate, selectedRate));

        params.set(LISTINGS_BUDGET_FILTER.options.max.name, nMax);
        params.set(LISTINGS_BUDGET_FILTER.options.min.name, nMin);

        router.push(`${pathname}?${params.toString()}`);
      }

      window.dispatchEvent(new Event('changeCurrency'));
    }
  };

  const getCurrencyName = (language: CurrencySymbols) => language.name;

  useEffect(() => {
    setTypedLocalStorageItem('currencies', currencies);
  }, [currencies, isMounted]);

  return (
    <Select<Currency, false>
      value={currency}
      instanceId="id-1312"
      options={selectOptions}
      onChange={onChangeSelect}
      getOptionLabel={getCurrencyName}
      getOptionValue={getCurrencyName}
      aria-label="currency"
      components={{
        Input: CurrencyInput,
        Option: CurrencyOption,
        SingleValue: CurrencyValue,
      }}
      isOptionDisabled={(option) => option.name === currency.name}
    />
  );
}
