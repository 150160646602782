export type MessagesCounters = Record<MessagesType, MessagesCountersData>;

interface MessagesCountersData {
  countMegaphoneAll: number;
  countMegaphoneNew: number;
  countDirectAll: number;
  countDirectNew: number;
}

export enum MessagesType {
  Inbox = 'Inbox',
  Archive = 'Archive',
  Spam = 'Spam',
  Other = 'Other',
}
