import { type Coordinates } from '@/features/place/types/place-types';
import { type UserCoordinatesResponse } from '@/features/place/types/user-coordinates-response-types';

import userCoordinatesAdapter from './adapters/user-coordinates-adapter';

export default async function getUserCoordinates(): Promise<Coordinates | null> {
  try {
    const result = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/v1/ipgeo`);
    const responseData = await result.json() as UserCoordinatesResponse;

    return userCoordinatesAdapter(responseData);
  } catch {
    return null;
  }
}
