'use client';

import './search-place.scss';

import cx from 'classix';
import debounce from 'lodash/debounce';
import { useParams } from 'next/navigation';
import {
  useLocale,
  useTranslations,
} from 'next-intl';
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  BsArrowRight,
  BsGeoAltFill,
  BsXLg,
} from 'react-icons/bs';
import {
  type GroupBase,
  type InputActionMeta,
  type OptionsOrGroups,
  type SelectInstance,
  type SingleValue,
} from 'react-select';
import AsyncSelect from 'react-select/async';
import { useIsMounted } from 'usehooks-ts';

import {
  DEFAULT_LISTING_PART_VARIANTS,
  LISTING_URL_PART_VARIANTS,
} from '@/features/listing/constants/listing-constants';
import { type ListingUrl } from '@/features/listing/types/listing-types';
import {
  getCookieCurrentPlace,
  setCookieCurrentPlace,
} from '@/features/place/cookies/cookie-current-place';
import {
  deleteCookieLastSearchedPlaces,
  setCookieLastSearchedPlaces,
} from '@/features/place/cookies/cookie-last-searched-places';
import {
  getCookieUserCoordinates,
  setCookieUserCoordinates,
} from '@/features/place/cookies/cookie-user-coordinates';
import getPlacesAutocompleteByName from '@/features/place/requests/get-places-autocomplete-by-name';
import getUserCoordinates from '@/features/place/requests/get-user-coordinates';
import getUserCurrentPlace from '@/features/place/requests/get-user-current-place';
import { type Place } from '@/features/place/types/place-types';
import askUserLocation from '@/features/place/utils/ask-user-location';
import placeNameToUrl from '@/features/place/utils/place-name-to-url';
import { useRouter } from '@/i18n/routing';
import Button from '@/ui/button/button';

import styles from './search-place.module.scss';
import SearchPlaceInput from './search-place-input/search-place-input';
import SearchPlaceOptionNew from './search-place-option/search-place-option-new';

interface SearchPlaceProps {
  lastSearchedPlaces: Place[] | null;
  currentPlace: Place | null;
  isNeedMoveForwardButton?: boolean;
  moveForwardButtonCallback?: () => void;
  isMainPage?: boolean;
}

export default function SearchPlace(props: SearchPlaceProps) {
  const {
    currentPlace,
    isMainPage,
    isNeedMoveForwardButton,
    lastSearchedPlaces,
    moveForwardButtonCallback,
  } = props;

  const translationsSearchPlace = useTranslations('place.search-place');
  const router = useRouter();
  const locale = useLocale();
  const params = useParams();
  const isMounted = useIsMounted();

  const selectRef = useRef<SelectInstance<Place> | null>(null);

  const millisecondsBeforeSearchStart = 500;
  const minimumSymbolsToStartSearch = 1;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const needMoreToStartSearchInitial = minimumSymbolsToStartSearch > 1;

  const [inputValue,
    setInputValue] = useState<Place['fullName']>(currentPlace?.fullName ?? '');
  const [placeOptions,
    setPlaceOptions] = useState<Place[]>(lastSearchedPlaces ?? []);
  const [needMoreToStartSearch,
    setNeedMoreToStartSearch] = useState<boolean>(needMoreToStartSearchInitial);
  const [nothingFound,
    setNothingFound] = useState(false);
  const [isShowSelectDropdown,
    setShowSelectDropdown] = useState(false);
  // const [isSelectFocused,
  //   setIsSelectFocused] = useState(false);

  const paramsListingType = params.listingType ?? 'rooms-for-rent';
  const currentListingType = LISTING_URL_PART_VARIANTS[paramsListingType as ListingUrl];
  const listingTypeSlug = DEFAULT_LISTING_PART_VARIANTS[currentListingType];

  useEffect(() => {
    const userCoordinatesCookie = getCookieUserCoordinates();
    const currentPlaceCookie = getCookieCurrentPlace();

    if (!userCoordinatesCookie) {
      getUserCoordinates()
        .then((userCurrentCoords) => {
          if (userCurrentCoords) {
            setCookieUserCoordinates(userCurrentCoords);
          }
        })
        .catch(() => null);
    }

    if (currentPlace?.fullName && (currentPlaceCookie?.fullName !== currentPlace.fullName)) {
      setCookieCurrentPlace({
        ...currentPlace,
        listingType: listingTypeSlug,
      });
      deleteCookieLastSearchedPlaces();
      setPlaceOptions([]);
    }
  }, [currentPlace, isMounted, listingTypeSlug]);

  const onShowNearMeClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      const userCurrentCoords = await askUserLocation();
      const userCurrentPlace: Place = await getUserCurrentPlace(userCurrentCoords);
      const placeLink = placeNameToUrl(userCurrentPlace.fullName);

      setCookieCurrentPlace({
        ...userCurrentPlace,
        listingType: listingTypeSlug,
      });
      setInputValue(userCurrentPlace.fullName);

      router.push(`/${listingTypeSlug}/${placeLink}`, { locale });
    })();
  };

  const getDropDownText = () => {
    if (needMoreToStartSearch) {
      return translationsSearchPlace('enter-more-symbols', { count: minimumSymbolsToStartSearch });
    }

    if (nothingFound) {
      return translationsSearchPlace('not-found');
    }

    return translationsSearchPlace('start-typing');
  };

  const getPlaceName = (place: Place) => place.fullName;

  const getPlaceValue = (place: Place) => place.id;

  const onChangeSelect = (option: SingleValue<Place>) => {
    if (option?.fullName) {
      setInputValue(option.fullName);

      const placeLink = placeNameToUrl(option.fullName);

      setCookieCurrentPlace({
        ...option,
        listingType: listingTypeSlug,
      });
      setCookieLastSearchedPlaces(placeOptions);

      if (moveForwardButtonCallback) {
        moveForwardButtonCallback();
      } else {
        router.push(`/${listingTypeSlug}/${placeLink}`, { locale });
      }
    }
  };

  const loadOptions = (value: string, callback: (options: OptionsOrGroups<Place, GroupBase<Place>>) => void) => {
    if (value.length >= minimumSymbolsToStartSearch) {
      getPlacesAutocompleteByName(value)
        .then((places) => {
          setNeedMoreToStartSearch(false);
          setNothingFound(places.length === 0);
          setPlaceOptions(places);

          callback(places);
        })
        .catch(() => null);
    } else {
      setNeedMoreToStartSearch(true);
    }
  };

  const debouncedLoadOptionsNew = useRef(debounce(loadOptions, millisecondsBeforeSearchStart));

  const handleInputValueChange = (updatedInputValue: string, actionMeta: InputActionMeta) => {
    if (actionMeta.action === 'input-change') {
      setInputValue(updatedInputValue);

      if (!isShowSelectDropdown) {
        setShowSelectDropdown(true);
      }
    }
  };

  const handleHideSelectDropdown = () => {
    setShowSelectDropdown(false);
  };

  const handleFocusSelect = () => {
    if (placeOptions.length > 0) {
      setShowSelectDropdown(true);
    }
  };

  const handleClearInputValue = () => {
    setInputValue('');

    if (selectRef.current) {
      selectRef.current.focus();
    }
  };

  return (
    <div
      className={cx(
        'search_place_wrapper',
        isMainPage && 'searchPlace__isMainPage',
        isMainPage && styles.searchPlace__isMainPage,
      )}
    >
      <button aria-label="search near" onClick={onShowNearMeClick} className={styles.searchPlace_geoIcon}>
        <BsGeoAltFill />
      </button>

      <AsyncSelect<Place>
        ref={selectRef}
        classNamePrefix="search_place"
        instanceId="search-place"
        name="places"
        id="search-place"
        aria-label="place"
        placeholder={translationsSearchPlace('placeholder')}
        inputValue={inputValue}
        defaultInputValue={currentPlace?.fullName}
        escapeClearsValue={false}
        cacheOptions
        defaultOptions={placeOptions}
        onChange={onChangeSelect}
        onInputChange={handleInputValueChange}
        onBlur={handleHideSelectDropdown}
        onFocus={handleFocusSelect}
        getOptionLabel={getPlaceName}
        getOptionValue={getPlaceValue}
        loadOptions={debouncedLoadOptionsNew.current}
        components={{
          Input: SearchPlaceInput,
          Option: SearchPlaceOptionNew,
        }}
        options={placeOptions}
        noOptionsMessage={getDropDownText}
        menuIsOpen={isShowSelectDropdown}
        isOptionSelected={(option) => option.fullName === currentPlace?.fullName}
      />

      {isNeedMoveForwardButton ? (
        <>
          <button aria-label="clear value" onClick={handleClearInputValue} className={cx(styles.searchPlace_clearIcon, styles.searchPlace_clearIcon__isMainPage)}>
            {/* eslint-disable-next-line canonical/prefer-react-lazy */}
            <BsXLg strokeWidth={0.8} />
          </button>

          <Button
            className={cx(styles.searchPlace_forwardIcon)}
            name="next"
            aria-label="next"
            shade="light"
            onClick={moveForwardButtonCallback}
            type="button"
            element="button"
            size="lg"
            variant="ghost"
          >
            {/* eslint-disable-next-line canonical/prefer-react-lazy */}
            <BsArrowRight strokeWidth={0.8} />
          </Button>
        </>
      ) : (
        <button aria-label="clear value" onClick={handleClearInputValue} className={styles.searchPlace_clearIcon}>
          {/* eslint-disable-next-line canonical/prefer-react-lazy */}
          <BsXLg />
        </button>
      )}

    </div>
  );
}
