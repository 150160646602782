import {
  deleteCookie,
  setCookie,
} from 'cookies-next/client';
import { type OptionsType } from 'cookies-next/server';
import { getCookie } from 'cookies-next/server';

import { type Place } from '@/features/place/types/place-types';
import { DEFAULT_COOKIE_SETTING } from '@/shared/constants/cookie-constants';

const cookieName = 'lastSearchedPlaces';

export async function getCookieLastSearchedPlaces(options?: OptionsType): Promise<Place[] | null> {
  const cookie = await getCookie(cookieName, options);

  if (cookie) {
    return JSON.parse(cookie) as Place[];
  }

  return null;
}

export function setCookieLastSearchedPlaces(places: Place[]): void {
  setCookie(cookieName, places, DEFAULT_COOKIE_SETTING);
}

export function deleteCookieLastSearchedPlaces(): void {
  deleteCookie(cookieName);
}
