import placeAdapter from '@/features/place/requests/adapters/place-adapter';
import { autocompleteByName } from '@/libs/geocode-earth/geocode-earth';

import { type Place } from '../types/place-types';

export default async function getPlacesAutocompleteByName(searchPlaceName: string): Promise<Place[]> {
  const result = await autocompleteByName(searchPlaceName);

  return result.features.map((feature) => placeAdapter(feature));
}
