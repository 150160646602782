'use client';

import cx from 'classix';
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import {
  BsStarFill,
  BsStarHalf,
} from 'react-icons/bs';

import useAppFlyerLink from '@/features/roomster-app/hooks/use-app-flyer-link';
import Button from '@/ui/button/button';

import styles from './app-banner.module.scss';

export default function AppBanner() {
  const translationsAppBanner = useTranslations('roomster-app.app-banner');
  const appFlyerLink = useAppFlyerLink('mobile', 'mobileTopBanner');

  return (
    <div className={styles.app_banner}>
      <div className={styles.banner_content}>
        <div className={styles.app_info}>
          <div className={styles.banner_text} dangerouslySetInnerHTML={{ __html: translationsAppBanner.raw('text') as string }} />

          <div className={cx(styles.app_rating, styles.app_rating_tablet)}>
            <BsStarFill />
            <BsStarFill />
            <BsStarFill />
            <BsStarFill />
            <BsStarHalf />
          </div>
        </div>

        <Image loading="eager" className={styles.app_image} src="/images/roomster-app/app-phones.png" alt="roomster app" width="115" height="164" />
      </div>

      <div className={styles.app_download}>
        <div className={cx(styles.app_rating, styles.app_rating_mobile)}>
          <BsStarFill />
          <BsStarFill />
          <BsStarFill />
          <BsStarFill />
          <BsStarHalf />
        </div>

        <Button
          href={`${appFlyerLink.pathname}?${appFlyerLink.query}`}
          element="nativeLink"
          variant="filled"
          size="lg"
          shade="light"
          fullWidth
          target="_blank"
        >
          {translationsAppBanner('button')}
        </Button>
      </div>
    </div>
  );
}
