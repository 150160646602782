import { type Coordinates } from '@/features/place/types/place-types';
import { type UserCoordinatesResponse } from '@/features/place/types/user-coordinates-response-types';

export default function userCoordinatesAdapter(responseData: UserCoordinatesResponse): Coordinates {
  const {
    lat,
    lon,
  } = responseData;

  return {
    latitude: Number(lat),
    longitude: Number(lon),
  };
}
