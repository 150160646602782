'use client';

import { type MouseEvent } from 'react';
import { BsGeoAltFill } from 'react-icons/bs';
import { type OptionProps } from 'react-select';
import { components } from 'react-select';

import {
  DEFAULT_LISTING_PART_VARIANTS,
  DEFAULT_LISTING_TYPE,
} from '@/features/listing/constants/listing-constants';
import { getCookieServiceType } from '@/features/listing/cookies/cookie-service-type';
import { type Place } from '@/features/place/types/place-types';
import placeNameToUrl from '@/features/place/utils/place-name-to-url';
import { Link } from '@/i18n/routing';

import styles from './search-place-option-new.module.scss';

export default function SearchPlaceOptionNew(props: OptionProps<Place>) {
  const {
    children,
    data,
  } = props;

  const locationLink = placeNameToUrl(data.fullName);
  const listingTypeCookie = getCookieServiceType();
  const listingTypeSlug = listingTypeCookie ? DEFAULT_LISTING_PART_VARIANTS[listingTypeCookie] : DEFAULT_LISTING_TYPE;

  // Handle of link redirect made by onChangeSelect,
  // link need to run prefetch of nextjs
  const onLinkClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
  };

  return (
    <components.Option {...props}>
      <Link
        onClick={onLinkClick}
        className={styles.option_link}
        href={`/${listingTypeSlug}/${locationLink}`}
        scroll
      >
        <div className={styles.option_icon}>
          <BsGeoAltFill />
        </div>
        <span>{children}</span>
      </Link>
    </components.Option>
  );
}
