import { type Coordinates } from '@/features/place/types/place-types';

export default function askUserLocation() {
  return new Promise((resolve: (value: Coordinates) => void, reject: (reason: GeolocationPositionError) => void) => {
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        resolve(coords);
      },
      (error) => {
        // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
        reject(error);
      },
    );
  });
}
