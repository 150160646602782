import { setCookie } from 'cookies-next';
import { type OptionsType } from 'cookies-next';
import { getCookie as getCookieClient } from 'cookies-next/client';
import { getCookie as getCookieServer } from 'cookies-next/server';

import { LISTING_URL_PART_VARIANTS } from '@/features/listing/constants/listing-constants';
import { type CurrentPlace } from '@/features/place/types/place-types';
import { DEFAULT_COOKIE_SETTING } from '@/shared/constants/cookie-constants';
import {
  type CookieGetData,
  type CookieReturnValue,
} from '@/shared/types/cookies-types';

export const currentPlaceCookieName = 'currentPlace';
// need to keep synchronized old and new app
export const oldAppSearchLocationCookie = 'searchLocation';
export const oldAppGeolocationCookie = 'geolocation';

export function getCookieCurrentPlace<IsServer extends boolean = false>(cookieOptions?: CookieGetData<IsServer>): CookieReturnValue<IsServer, CurrentPlace> | null {
  if (cookieOptions?.isServer) {
    return (async () => {
      const cookie = await getCookieServer(currentPlaceCookieName, cookieOptions.options);

      if (cookie) {
        return JSON.parse(cookie) as CurrentPlace;
      }

      return null;
    })() as CookieReturnValue<IsServer, CurrentPlace>; // Ensure proper type casting for Promise
  }

  const cookie = getCookieClient(currentPlaceCookieName);

  if (cookie) {
    return JSON.parse(cookie) as CookieReturnValue<IsServer, CurrentPlace>;
  }

  return null;
}

export function setCookieCurrentPlace(place: CurrentPlace, options?: OptionsType): void {
  const geolocationCookieDataKey = `${place.fullName.replaceAll(',', '').replaceAll(' ', '-').toLowerCase()}-${LISTING_URL_PART_VARIANTS[place.listingType]}`;
  const geolocationCookieData = {
    [geolocationCookieDataKey]: {
      geo: {
        country_code: null,
        lat_ne: place.bbox.latitudeNorthEast,
        lat_sw: place.bbox.latitudeSouthWest,
        lng_ne: place.bbox.longitudeNorthEast,
        lng_sw: place.bbox.longitudeSouthWest,
        radius_scale: 1,
      },
      searchLocation: place.fullName,
    },
  };

  Promise.all([
    setCookie(currentPlaceCookieName, place, { ...DEFAULT_COOKIE_SETTING, ...options }),
    setCookie(oldAppSearchLocationCookie, place.fullName, { ...DEFAULT_COOKIE_SETTING, ...options }),
    setCookie(oldAppGeolocationCookie, geolocationCookieData, DEFAULT_COOKIE_SETTING),
  ]).catch(() => null);
}
