import Image from 'next/image';
import { type OptionProps } from 'react-select';
import { components } from 'react-select';

import { type Language } from '@/features/language/types/language-types';

import styles from './language-option.module.scss';

export default function LanguageOption(props: OptionProps<Language>) {
  const {
    children,
    data,
  } = props;

  const country = data.locale.split('-')[1].toLowerCase();

  return (
    <components.Option {...props}>
      <Image src={`/images/country-flags-icons/${country}.svg`} alt={data.name} width={26} height={18} />
      <span className={styles.option_value}>{children}</span>
    </components.Option>
  );
}
