import {
  getCookie as getCookieClient,
  setCookie,
} from 'cookies-next/client';
import { getCookie as getCookieServer } from 'cookies-next/server';

import { DEFAULT_COOKIE_SETTING } from '@/shared/constants/cookie-constants';
import {
  type CookieGetData,
  type CookieReturnValue,
} from '@/shared/types/cookies-types';

const cookieName = 'currency';

export function getCookieCurrency<IsServer extends boolean = false>(cookieOptions?: CookieGetData<IsServer>): CookieReturnValue<IsServer, string> {
  if (cookieOptions?.isServer) {
    return (async () => {
      const cookie = await getCookieServer(cookieName, cookieOptions.options);

      return cookie ?? 'USD';
    })() as CookieReturnValue<IsServer, string>;
  }

  const cookie = getCookieClient(cookieName);

  return (cookie ?? 'USD') as CookieReturnValue<IsServer, string>;
}

export function setCookieCurrency(currency: string): void {
  setCookie(cookieName, currency, DEFAULT_COOKIE_SETTING);
}
