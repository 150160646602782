import placeAdapter from '@/features/place/requests/adapters/place-adapter';
import { searchByCoordinates } from '@/libs/geocode-earth/geocode-earth';

import {
  type Coordinates,
  type Place,
} from '../types/place-types';

export default async function getUserCurrentPlace(coords: Coordinates): Promise<Place> {
  const result = await searchByCoordinates(coords);

  return placeAdapter(result.features[0]);
}
